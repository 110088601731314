module services {
    export module customs {
        export class customsService implements interfaces.customs.ICustomsService {
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            GetCustomsReconList(): ng.resource.IResourceClass<interfaces.customs.IClearingInstructionReconHeaderSearch> {
                return this.$resource<interfaces.customs.IClearingInstructionReconHeaderSearch>(this.ENV.DSP_URL + "ClearingInstructionReconDetail/GetCustomsReconList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber'
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        params: {
                            numberRecords: 25,
                            pageNumber: 1,
                        }
                    }
                });
            }

            GetCustomsReconListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'ClearingInstructionReconDetail/GetCustomsReconListExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getCustomsStatusList(classificationId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "ClassificationValue/GetDropdownListByClassification", {
                    classificationId: classificationId
                });
            }

            GetRecon(ciId: number): ng.resource.IResourceClass<interfaces.customs.IClearingInstructionRecon> {
                return this.$resource<interfaces.customs.IClearingInstructionRecon>(this.ENV.DSP_URL + "ClearingInstructionReconDetail/GetRecon", {
                    ciId: ciId
                }, {
                    get: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }

            RerunRecon(gdId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ClearingInstructionReconDetail/RerunRecon", {
                    gdId: gdId
                });
            }

            Submit(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ClearingInstructionReconDetail/Submit");
            }

            BulkUpdateSubmit(CurrentReconId: number, FieldName: string, Comments: string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ClearingInstructionReconDetail/BulkUpdateSubmit", {
                    CurrentReconId: CurrentReconId,
                    FieldName: FieldName,
                    Comments: Comments
                });
            }
        }
    }
    angular.module("app").service("customsService", services.customs.customsService);
}